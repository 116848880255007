import React from "react";
import "./HeroSection.css";
import "../App.css";
import { Button } from "./Button";
import videoOne from "../assets/videos/video-1.mp4";

export default function HeroSection() {
  return (
    <div className="hero-container">
      <video src={videoOne} autoPlay loop muted />
      <h1>
        Product Manager <br></br>
        Developer <br></br> 
        Analyst <br></br> 
      </h1>
      <p>Let's build together 🚀</p>
      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
        >
          Resume <i className="fas fa-file-alt" />
        </Button>
      </div>
    </div>
  );
}
