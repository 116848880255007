import { Link } from "react-router-dom";
import React, { useState } from "react";
import "./Navbar.css";

export default function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <i className="fas fa-terminal"></i> Will
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <a
                href="https://www.linkedin.com/in/willbenedict/"
                className="nav-links"
                onClick={closeMobileMenu}
                class="social-icon-link linkedin"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <i class="fab fa-linkedin" />
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://twitter.com/WILLBENEDICT"
                className="nav-links"
                onClick={closeMobileMenu}
                class="social-icon-link twitter"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
              >
                <i class="fab fa-twitter" />
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://github.com/wjb108"
                className="nav-links"
                onClick={closeMobileMenu}
                class="social-icon-link twitter"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Github"
              >
                {" "}
                <i class="fab fa-github"></i>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="mailto:williambenedict3@gmail.com"
                className="nav-links"
                onClick={closeMobileMenu}
                class="social-icon-link twitter"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Email"
              >
                <i class="fas fa-paper-plane" />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
